<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">集污点查询</h1>
    </div>
    <div slot="extra">
      <a-button icon="export" type="default" style="margin-left: 10px" :loading="exportCarLoading" @click="exportWordSpot">导出集污点</a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="编号">
              <a-input v-model="searchParams.no" @change="spotSearch" placeholder="请输入编号" style="width: 120px"/>
            </a-form-item>
            <a-form-item label="集污点名称">
              <a-input v-model="searchParams.name" @change="spotSearch" placeholder="请输入集污点名称" style="width: 120px"/>
            </a-form-item>
            <a-form-item label="姓名">
              <a-input v-model="searchParams.userName" @change="spotSearch" placeholder="请输入农户姓名" style="width: 120px"/>
            </a-form-item>
            <a-form-item label="手机">
              <a-input v-model="searchParams.userPhone" @change="spotSearch" placeholder="请输入手机号" style="width: 120px"/>
            </a-form-item>
      <!--      <a-form-item label="审核">
              <a-radio-group @change="checkType" v-model="searchParams.checkStatus">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="PASS">通过</a-radio-button>
                <a-radio-button value="REJECT">驳回</a-radio-button>
                <a-radio-button value="UN_CHECK">未审核</a-radio-button>
              </a-radio-group>
            </a-form-item>-->
            <a-form-item label="类型">
              <a-select default-value=""
                        style="width: 110px"
                        @change="selectType"
                        v-model="searchParams.type">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="spotTypeEnum.DANWENG.value">单翁</a-select-option>
                <a-select-option :value="spotTypeEnum.JIWUCHI.value">集污池</a-select-option>
                <a-select-option :value="spotTypeEnum.SANGE.value">三格化粪池</a-select-option>
                <a-select-option :value="spotTypeEnum.LANI.value">拉泥</a-select-option>
                <a-select-option :value="spotTypeEnum.GONGCE.value">公厕</a-select-option>
                <a-select-option :value="spotTypeEnum.OTHER.value">其他</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <area-selection @onclickArea="onclickArea" @search="spotSearch"></area-selection>
            </a-form-item>
            <a-form-item label="清运时间">
              <a-range-picker @change="dateChange" style="width: 260px;" >
                <a-icon slot="suffixIcon" type="calendar"/>
              </a-range-picker>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="spotSearch" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
        <!--      <a :style="{ marginLeft: '10px', fontSize: '12px' }" @click="toggle">
                {{ expand ? '收起' : '展开' }}
                <a-icon :type="expand ? 'up' : 'down'"/>
              </a>-->
            </a-form-item>
            <a-row v-if="expand" style="margin-top: 10px;margin-bottom: 10px;">

       <!--       <a-form-item label="微信绑定">
                <a-radio-group @change="bindType" v-model="searchParams.bindType">
                  <a-radio-button value="">全部</a-radio-button>
                  <a-radio-button value="BIND">已绑定</a-radio-button>
                  <a-radio-button value="NOT">未绑定</a-radio-button>
                </a-radio-group>
              </a-form-item>-->
        <!--      <a-form-item label="是否清运过">
                <a-radio-group v-model="searchParams.qyFlag">
                  <a-radio-button value="">全部</a-radio-button>
                  <a-radio-button value="true">是</a-radio-button>
                  <a-radio-button value="false">否</a-radio-button>
                </a-radio-group>
              </a-form-item>-->
            </a-row>
            <a-row :gutter="[16,16]">
              <a-spin :spinning="spinning">
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card
                    class="mouse-pointer"
                    :hoverable="true"
                    :style="{background: searchParams.qyFlag === ''? 'rgb(214, 247, 232)' : ''}"
                    @click="onClickStatisticCard('')">
                    <a-statistic
                      title="集污点总数"
                      :value="spotDataStatistics.spotTotal"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card
                    :hoverable="true"
                    :style="{background: searchParams.qyFlag === 'true'? 'rgb(214, 247, 232)' : ''}"
                    @click="onClickStatisticCard('true')">
                    <a-statistic
                      title="清运过"
                      :value="spotDataStatistics.cleanSpotTotal"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card
                    :hoverable="true"
                    :style="{background: searchParams.qyFlag === 'false'? 'rgb(214, 247, 232)' : ''}"
                    @click="onClickStatisticCard('false')">
                    <a-statistic
                      title="未清运过"
                      :value="spotDataStatistics.noCleanSpotTotal"
                      :valueStyle="valueStyle"
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6">
                  <a-card
                  >
                    <a-statistic
                      title="清运次数"
                      :value="spotDataStatistics.cleanTimeTotal"
                      :valueStyle="valueStyle"
                      style="text-align: center; color: #1890ff;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
              </a-spin>
            </a-row>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :customRow="handleView"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        :scroll="{ x: 1600 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.page * searchParams.size + index + 1 }}
        </template>
        <template slot="name" slot-scope="text, record">
          <div v-if="record.collectType==='WECHAT'">
            <a-icon style="color:#2ba245" type="wechat" theme="filled"/>&nbsp;
            <a-icon type="swap" style="font-size: 14px"/>&nbsp;{{ record.name }}
          </div>
          <div v-else-if="record.collectType==='REGISTER'">
            <a-icon style="color:#2b97ff" type="phone" theme="filled"/>&nbsp;
            <a-icon type="swap" style="font-size: 14px"/>&nbsp;{{ record.name }}
          </div>
          <div v-else-if="record.collectType==='IMPORT'">
            <a-icon style="color:#5007FF" type="import"/>&nbsp;
            <a-icon type="swap" style="font-size: 14px"/>&nbsp;
            {{ record.name }}
          </div>
        </template>
        <template slot="type" slot-scope="text, record">
          <span v-if="record.type===spotTypeEnum.DANWENG.value">
            单翁
          </span>
          <span v-if="record.type===spotTypeEnum.JIWUCHI.value">
            集污池
          </span>
          <span v-if="record.type===spotTypeEnum.SANGE.value">
            三格化粪池
          </span>
          <span v-if="record.type===spotTypeEnum.LANI.value">
            拉泥
          </span>
          <span v-if="record.type===spotTypeEnum.GONGCE.value">
            公厕
          </span>
          <span v-if="record.type===spotTypeEnum.OTHER.value">
            其他
          </span>
        </template>
        <template slot="checkStatus" slot-scope="text, record">
          <a-tag v-if="record.checkStatus==='PASS'" color="#00CD66">
            通过
          </a-tag>
          <a-button v-if="record.checkStatus==='UN_CHECK'" size="small" type="link">
            审核
          </a-button>
          <a-tag v-if="record.checkStatus==='REJECT'" color="#ff0000">
            驳回
          </a-tag>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-drawer
      title="集污点详细信息"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="1200px"
      :visible="detailDrawer"
      :mask="false">
      <spot-detail ref="viewRef"></spot-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import '../../../plugins/fileDownload/download.min'
import VueCookie from 'vue-cookie'
import { DEFAULT_X_AUTH_TOKEN } from '../../../store/mutation-types'

import { gatherspotSeachColumns } from './common/spotCommon'
import PageLayout from '@/components/page/PageLayout'
import SpotDetail from './Detail'
import entityCRUD from '../../common/mixins/entityCRUD'
import moment from 'moment'
import AreaSelection from '../statistics/loophistory/AreaSelection'
import { spotTypeEnum } from '@/constant/spot/spotType'

export default {
  name: 'GatherSpotList',
  components: { PageLayout, SpotDetail, AreaSelection },
  mixins: [entityCRUD],
  data () {
    return {
      //导入
      importModel: false,
      importUrl: SERVICE_URLS.csgz.gatherSpot.import,
      editLoading: false,
      entityBaseUrl: SERVICE_URLS.csgz.gatherSpot,
      detailDrawer: false,
      moreEditModal: false,
      addModal: false,
      dispatchModal: false,
      feedbackModal: false,
      confirmModal: false,
      checkModal: false,
      expand: false,
      selectedRowKeys: [],
      tempIdList: [],
      userType: '',
      modalTitle: '',
      searchParams: {
        page: '0',
        size: '10',
        direction: '',
        order: '',
        no: '',
        name: '',
        type: '',
        bindType: '',
        checkStatus: 'PASS',
        userPhone: '',
        userName: '',
        qyFlag: '',
        codes: '',
        startTime: '',
        endTime: ''
      },
      initColumns: gatherspotSeachColumns(),
      tableData: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
      },
      valueStyle: {
        color: '',
        textAlign: 'center'
      },
      datePickerValue: moment().format('YYYY'),
      visible: false,
      confirmLoading: false,
      bindingModel: false,
      selectSpotInfo: {},
      spotTypeEnum: spotTypeEnum,
      spotDataStatistics: { spotTotal: 0, cleanSpotTotal: 0, noCleanSpotTotal: 0, cleanTimeTotal: 0 },
      spinning: false,
      exportCarLoading: false,
    }
  },
  created () {
    this.search()
    this.loadSpotDataStatistics()
  },
  computed: {
    currentUser () {
      return this.$store.getters.currentUser
    }
  },
  methods: {
    moment,
    toggle () {
      this.expand = !this.expand
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.tempIdList = []
      this.selectedRowKeys = selectedRowKeys
      for (let item of selectedRows) {
        this.tempIdList.push(item.id)
      }
    },
    selectType () {
      this.spotSearch()
    },
    bindType () {
      this.spotSearch()
    },
    checkType () {
      this.spotSearch()
    },
    detailClose () {
      this.detailDrawer = false
      //重置报抽查询条件按照编号查询
      this.$refs.viewRef.resetData()
    },
    handleView (record) {
      return {
        on: {
          click: () => {
            this.detailDrawer = true
            this.$nextTick(() => {
              //重置报抽查询条件按照编号查询
              this.$refs.viewRef.resetData()
              this.$refs.viewRef.loadData(record.id)
            })
          }
        }
      }
    },
    tableChange (pagination, filters, sorter) {
      this.searchParams.page = pagination.current - 1
      this.searchParams.size = pagination.pageSize
      this.search()
    },
    search () {
      this.$http(this, {
        url: this.entityBaseUrl.searchSpot,
        noTips: true,
        loading: 'tableLoading',
        data: this.searchParams,
        success: (data) => {
          this.tableData = data.body.content
          this.pagination.total = data.body.totalElements
          this.pagination.current = data.body.number + 1
          this.pagination.pageSize = data.body.size
          this.searchParams.size = data.body.size
          this.searchParams.page = data.body.number
        }
      })
    },
    loadSpotDataStatistics () {
      let para = {}
      Object.assign(para, this.searchParams)
      para.qyFlag = ''
      this.$http(this, {
        url: this.entityBaseUrl.searchSpotTotal,
        noTips: true,
        loading: 'tableLoading',
        data: para,
        success: (data) => {
          this.spotDataStatistics = data.body
        }
      })
    },
    exportWordSpot () {
      this.exportCarLoading = true
      this.$http(this, {
        url: this.entityBaseUrl.exportSpotTotal,
        noTips: true,
        loading: 'tableLoading',
        data: this.searchParams,
        success: (data) => {
          let downUrl = SERVICE_URLS.document.download.path.replace('{id}', data.body.id) + '?attname=' + encodeURIComponent(data.body.name)
          const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
          if (!xAuthToken) {
            this.$Message.error({
              closable: true,
              content: '下载凭证丢失，请重新登录！',
              duration: 3
            })
            return
          }
          downUrl += '&' + DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken
          // eslint-disable-next-line
          downloadFile(downUrl)
          this.exportCarLoading = false
        }, error: () => {
          this.exportCarLoading = false
        }
      })
    },
    closeBindingModal () {
      this.bindingModel = false
    },
    selectedTreeNode (key, node) {
      if (key.length > 0) {
        this.selectSpotInfo.monitorPointNo = node.dataRef.no
        this.selectSpotInfo.monitorPointName = node.dataRef.title
      } else {
        this.selectSpotInfo.monitorPointNo = ''
        this.selectSpotInfo.monitorPointName = ''
      }
    },
    onclickArea (areaCodes) {
      this.searchParams.codes = areaCodes.join(',')
    },
    spotSearch () {
      this.searchParams.page = 0
      this.search()
      this.loadSpotDataStatistics()
    },
    onClickStatisticCard (flag) {
      this.searchParams.qyFlag = flag
      this.searchParams.page = 0
      this.search()
    },
    dateChange (date, dateString) {
      this.searchParams.startTime = dateString[0]
      this.searchParams.endTime = dateString[1]
      this.spotSearch()
    },
  }
}
</script>
<style>
  .mouse-pointer {
    cursor: pointer
  }
</style>